/** @format */

import React from "react";

const Header = () => {
  const url = window.location.pathname;
  return (
    <div>
      {url === "/" ? (
        <div className="home-main-header">
          <a href="https://www.qalara.com/"><img src="/images/Qalara_logo.png" alt="qalara" width="185px" className="qalara-logo-padding" /></a>
          <div className="qrafted-logo-container">
            <a href="/"><img src="/images/logo.png" alt="qrafted" width="185px" /></a>
            <p className="home-logo-text">presents live mark</p>
          </div>
          <p style={{opacity:"0",width:"185px"}}></p>
        </div>
      ) : (
        <div>
          <div className="head-figure">
            <a href="/"><img src="/images/logo.png" alt="qrafted" width="185px" /></a>
          </div>
          <div className="figcaption">presents</div>
          <div className="figcaption bold uppercase livemark">livemark</div>
          <div className="figcaption livemark-desc">
            A digital endeavour bringing transparency to <br /> the global
            artisanal supply chain
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
