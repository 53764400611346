/** @format */
import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./styles/reset.css";
import "./App.css";
import Header from "./components/Header";

const MeasuringSpoon = lazy(() => import("./components/MeasuringSpoon"));
const OvenMitt = lazy(() => import("./components/OvenMitt"));
const KitchenTowels = lazy(() => import("./components/KitchenTowels"));
const BowlSet = lazy(() => import("./components/BowlSet"));
const ButterKeeper = lazy(() => import("./components/ButterKeeper"));
const OilBurner = lazy(() => import("./components/OilBurner"));
const WineRack = lazy(() => import("./components/WineRack"));
const CoasterSet = lazy(() => import("./components/CoasterSet"));
const BananaBarkTray = lazy(() => import("./components/BananaBarkTray"));
const Home = lazy(() => import("./components/home"));

const App = () => (
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route
          exact
          path="/livemark/measuringspoons/:id"
          component={MeasuringSpoon}
        />
        <Route exact path="/livemark/ovenmitt/:id" component={OvenMitt} />
        <Route
          exact
          path="/livemark/kitchentowels/:id"
          component={KitchenTowels}
        />
        <Route exact path="/livemark/bowlset/:id" component={BowlSet} />
        <Route
          exact
          path="/livemark/butterkeeper/:id"
          component={ButterKeeper}
        />
        <Route exact path="/livemark/oilburner/:id" component={OilBurner} />
        <Route exact path="/livemark/winerack/:id" component={WineRack} />
        <Route exact path="/livemark/coasterset/:id" component={CoasterSet} />
        <Route
          exact
          path="/livemark/bananabarktray/:id"
          component={BananaBarkTray}
        />
      </Switch>
    </Suspense>
  </Router>
);

export default App;
